.vip-code {
    text-align: center;
}

.landing-vip-single-input-container {
    margin-top: 4rem;
    margin-bottom: 1rem;
    width: 100%;
    color: black;
}

.fabProgress {
    color: green;
    position: absolute;
    top: -6px;
    left: -6px;
    z-index: 1;
}

.buttonSuccess {
    background-color: green;
}

.buttonClassname {
    margin-left: -2rem !important;
    position: absolute !important;
    background-color: #B59A4F !important;
    padding: 2rem !important;
    margin-top: -4px !important;
}

@media screen and (max-width: 768px) {
    .landing-vip-single-input-container {
        width: 80%;
    }
}

.button-div {
    display: inline;
    position: relative;
}

.fabProgress {
    left: -2rem !important;
    top: -8px !important;
    color: #424242 !important;
}

.MuiFormHelperText-contained {
    padding-left: 14px !important;
    padding-right: 14px !important;
    background-color: #424242 !important;
    color: #B59A4F !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.MuiFab-label {
    height: 4px !important;
}

.MuiFab-root.Mui-disabled {
    color: white !important;
    background-color: grey !important;
}
