.form-container {
    min-height: 200px;
    padding: 1rem;
    max-width: 60%;
}

@media screen and (min-width: 769px) {
    .upload-success-container {
        color: #fff;
        text-align: left;
        border-radius: 0;
        min-height: 400px;
        position: relative;
        top: 30%;
    }
}

@media screen and (max-width: 768px) {
    .upload-success-container {
        color: #fff;
        text-align: left;
        border-radius: 0;
        min-height: 300px;
        position: relative;
        top: 30%;
    }

    .form-container {
        min-height: 200px;
        padding: 0rem;
        max-width: 60%;
    }
}

.informations-container {
    display: inline-flex;
    flex-direction: row;
    width: 90%;
    margin-top: 1.5rem;
    max-width: 500px;
    padding-left: 1rem;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .form-container {
        padding: 0 0 0 0.5rem;
        width: 75%;
    }

    .informations-container {
        width: 100%;
    }
}

.data-consumption-text {
    color: #fff;
    max-width: 500px;
    margin: 0 auto;
    text-align: justify;
    text-align-last: center;
    padding: 1rem;
    font-size: 0.6rem;
}

.description-text {
    color: white;
    position: relative;
    top: 1rem;
    width: 70%;
    margin: 0 auto;
    text-align: justify;
    text-align-last: center;
    font-weight: 500;
    max-width: 500px;
}

.description-text-header-container {
    /* padding-top: 5rem; */
    padding-right: 2%;
    display: flex;
    flex-direction: row;
    max-width: 500px;
    margin: 0 auto;
}

.loading {
    padding: 5rem;
}

.countdown {
    color: white;
    padding: 3rem;
    font-size: 1.2rem;
}
