.ant-upload.ant-upload-select-picture-card, .ant-upload-list-picture-card-container {
    width: 100%;
    height: 150px;
    background-color: transparent;
}

.ant-upload.ant-upload-select-picture-card:hover, .ant-upload-list-picture-card-container:hover {
    border-color: rgb(211 175 95);
    color: #B59A4F;
}

.upload {
    color: white;
}

.upload:hover {
    color: #B59A4F;
}
