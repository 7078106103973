.react-select__control {
    background-color: transparent !important;
    border-color: rgba(255, 255, 255, 0.3) !important;
}

.react-select__control:hover {
    border-color: rgb(211, 175, 95) !important;
}

.react-select__control--is-focused {
    border: 1px solid rgb(211, 175, 95) !important;
    box-shadow: 0 0 0 1px rgb(211, 175, 95) !important;
}

.react-select__input {
    color: #E8EDDF;
}

.react-select__menu {
    background-color: #333533 !important;
    color: #E8EDDF !important;
}

.react-select__menu-list {
    background-color: #333533 !important;
    color: #E8EDDF !important;
}

.react-select__option {
    background-color: #333533 !important;
    color: #E8EDDF !important;
}

.react-select__value-container {
    color: #333533 !important;
}

.react-select__multi-value {
    background-color: rgb(211, 175, 95) !important;
}

.react-select__multi-value__label {
    background-color: rgb(211, 175, 95) !important;
}

.react-select__multi-value__remove:hover {
    background-color: #3335331c !important;
    color: #CFDBD5 !important;
}

.react-select__indicator-separator {
    background-color: #242423 !important;
}
