.mail-validation-informations-container {
    display: inline-flex;
    flex-direction: row;
    width: 100%;
    margin-top: 0rem;
    position: relative;
    left: 50%;
    bottom: 20%;
    transform: translateX(-50%);
}

.mail-validation-box-name-picture {
    width: 100%;
    min-height: 200px;
    position: relative;
    background-image: url('../../assets/fanartzone.JPG');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.ant-spin-dot-item {
    background-color: rgb(181, 154, 79) !important;
}

.ant-spin-text {
    color: rgb(181, 154, 79) !important;
}

.mail-validation-message {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: calc(100vh - (230px + 56px)); /* footer + header  */
    min-height: 50px;
    color: white;
    text-align: center;
}
