.header-vip-logo {
    background-image: url('../../assets/logo_vip.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 200px;
    height: 120px;
    margin: 0 auto;
    background-position: -50px -10px;
}

.header-fan-logo {
    position: absolute;
    left: 0;
    background-image: url('../../assets/logo.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 200px;
    height: 120px;
    margin: 0 auto;
    background-position: -50px -10px;
}

.header-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}

.header-title {
    position: absolute;
    font-size: 32px;
    z-index: 99;
}

.ads-header-logo {
    position: relative;
    top: 2rem;
    left: 50%;
    transform: translateX(-25%);
    width: 500px;
    height: 100px;
    background-image: url("../../assets/cgc_transparent.png");
    background-size: 50% auto;
    background-repeat: no-repeat;
}

.ads-header-world {
    position: relative;
    width: 200px;
    height: 150px;
    background-image: url("../../assets/cgc_transparent.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
}

@media screen and (min-width: 481px) {
    .landing-name {
        /* font-size: 30px;
        color: rgb(181, 154, 79);
        align-self: flex-end;
        font-weight: bold;
        font-family: Apple; */
        background-size: contain;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center;
        width: 100vw;
        min-height: 480px;
        height: auto;
        margin-top: -60px;
        position: relative;
    }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
    .landing-name {
        /* font-size: 30px;
        color: rgb(181, 154, 79);
        align-self: flex-end;
        font-weight: bold;
        font-family: Apple; */
        background-size: auto;
        width: 100vw;
        min-height: 35vh;
        max-height: 50vh;
        height: auto;
        margin-top: -63px;
        position: relative;
    }
}

.header-submenu {
    height: 90px;
    width: 100%;
}

@media screen and (min-width: 320px) and (max-width: 425px) {
    .header-image {
        max-width: 100%;
        max-height: 350px;
    }
}

@media screen and (min-width: 426px) {
    .header-image {
        max-width: 500px;
        height: 500px;
    }
}
