.footer-container {
    width: 100%;
    height: 230px;
    text-align: center;
    padding-top: 2rem;
    /* background-color: #424242f2 !important; */
}

.footer-keep-in-touch {
    color: #B59A4F;
    font-family: raleway, sans-serif;
    font-size: 13px;
    font-weight: 700;
}

.footer-icons-container {
    margin-top: 1rem;
}

.footer-icons {
    color: #fff;
    width: 29px !important;
    height: 29px !important;
    margin-right: 0.5rem;
}

.footer-company-text {
    color: #fff;
    font-size: 13px;
    font-weight: 700;
    font-family: raleway, sans-serif;
}

.footer-company-location {
    color: #fff;
    font-size: 13px;
    font-family: raleway, sans-serif;
}

.footer-company-mail {
    color: #fff;
    font-size: 13px;
    font-family: raleway, sans-serif;
}
