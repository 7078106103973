.menu-logo {
  width: 50px;
  height: 56px;
  position: absolute;
  top: 5px;
  left: 5px;
  background: url('../../assets/faz.png') no-repeat center center;
  background-size: cover;
}

.submenu-position {
  position: absolute;
  bottom: 0;
  width: 100%;
}